<template>

    <div>
        <h1 class="tipx">请及时更改用户初始密码!</h1>
        <center style="max-width: 800px;">
            <div class="indexStyle">
                <div class="itemBox" style="background-color:#409EFF">
                    <p>充值总数:</p>
                    <p>{{ user.pay_num }}</p>
                </div>
                <div class="itemBox" style="background-color:pink">
                    <p>分配数量:</p>
                    <p>{{ user.allot_num }}</p>
                </div>
                <div class="itemBox" style="background-color:green">
                    <p>剩余条数:</p>
                    <p>{{ user.pay_num - user.allot_num }}</p>
                </div>
            </div>
        </center>
    </div>

</template>
<script scoped>
import HeaderTop from '@/components/top.vue'
import LeftMenu from '@/components/left.vue'
import Footer from '@/components/footer.vue'
export default {
    name: 'UserIndexView',
    // 注册组件
    components: {
        LeftMenu,
        HeaderTop,
        Footer
    },
    // data() 为变量赋值等

    data() {
        return {
            user: {}
        }
    },

    created() {
        // 从缓存获取user
        this.cache_user = JSON.parse(sessionStorage.getItem('user'))
        this.onload()
    },
    methods: {
        onload() {
            let that = this
            let req = {
                user_id: Number(this.cache_user.user_id)
            }
            this.myAxios.post(that.myBaseurl + '/user/index', req)
                .then(function (res) {
                    if (res.data.Code === '200') {
                        console.log(res.data.Data);
                        that.user = res.data.Data
                        if (that.user.user_level == 1 || that.user.user_level == 2) {
                            that.user.pay_num = that.user.pay_num
                        }
                    } else {
                        alert("获取用户信息失败!")
                    }
                })
        }

    }
}

</script>

<style lang="less" scoped="scoped">
body {
    padding: 0px;
    margin: 0px;
    font-size: 18px;
}

/*主框体样式*/

.tipx {
    height: 30px;
    line-height: 30px;
    font-family: "微软雅黑";
    font-weight: 100;
    margin: 2px;
    padding: 0px;
    color: #e6a23c;
    margin-left: 400px;
}

.indexStyle {
    width: 920px;
    height: 600px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 200px;

    .itemBox {
        font-family: "楷体";
        width: 200px;
        height: 200px;
        margin: 0px 50px;
        // color: white;
        font-weight: 800;
        font-size: 20px;
        line-height: 100px;
        border-radius: 50%;
    }
}
</style>
